import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faJsSquare, faUnity, faReact } from "@fortawesome/free-brands-svg-icons";
import { faFileCode } from "@fortawesome/free-solid-svg-icons";

class Skills extends Component {
  render() {
    var sectionName = "Skills";

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
          <ul className="list-inline mx-auto skill-icon">
  <li className="list-inline-item mx-3">
    <span>
      <div className="text-center skills-tile">
        <FontAwesomeIcon icon={faJsSquare} style={{ fontSize: "220%" }} />
        <p className="text-center" style={{ fontSize: "70%", marginTop: "4px" }}>
          JavaScript
        </p>
      </div>
    </span>
  </li>
  <li className="list-inline-item mx-3">
    <span>
      <div className="text-center skills-tile">
        <FontAwesomeIcon icon={faFileCode} style={{ fontSize: "220%" }} />
        <p className="text-center" style={{ fontSize: "70%", marginTop: "4px" }}>
          TypeScript
        </p>
      </div>
    </span>
  </li>
  <li className="list-inline-item mx-3">
    <span>
      <div className="text-center skills-tile">
        <FontAwesomeIcon icon={faReact} style={{ fontSize: "220%" }} />
        <p className="text-center" style={{ fontSize: "70%", marginTop: "4px" }}>
          React
        </p>
      </div>
    </span>
  </li>
  <li className="list-inline-item mx-3">
    <span>
      <div className="text-center skills-tile">
        <FontAwesomeIcon icon={faUnity} style={{ fontSize: "220%" }} />
        <p className="text-center" style={{ fontSize: "70%", marginTop: "4px" }}>
          Unity
        </p>
      </div>
    </span>
  </li>
</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
